import React from "react";
import NavBar from "./Components/NavBar/NavBar";
import Home from "./Components/Home/Home";
import Contactus from "./Components/ContactUs/Contactus";
import Footer from "./Components/Footer/Footer";
import Tittle from "./Components/Tittle/Tittle";
import Aboutus from "./Components/About/Aboutus";
import Card from "./Components/Card/Card";
import GroupageSerivce from "./Components/GroupageSerivce/GroupageSerivce";
import MainCard from "./Components/Contact-Card/MainCard";
import Video from "./Components/Video/Video";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Home />
      <div className="container">
        <Tittle content="About us" />
        <Aboutus />
      </div>
      <div className="container">
        <Tittle content="Groupage Serivce" />
        <GroupageSerivce />
      </div>
      <Tittle content=" Our Services" />
      <Card />
      <Video />

      <div className="container">
        <Tittle content="Contact us" />
      </div>
      <Contactus />
      <MainCard />
      <Footer />
    </div>
  );
}

export default App;
