import React, { useEffect } from "react";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2000,
});

function Home() {
  useEffect(() => {
    const textElement = document.querySelector(".typing-text");
    const textArray = [
      "Consol Express",
      "Sea Freight",
      "Air Freight",
      "Road Freight",
      "Customs Clearence",
      "Project Cargo",
      "Ware Housing",
    ];
    let textIndex = 0;
    let charIndex = 0;
    let animatedText = "";
    let typingTimeout;
    let erasingTimeout;

    const typeAnimation = () => {
      if (charIndex < textArray[textIndex].length) {
        animatedText += textArray[textIndex][charIndex];
        textElement.textContent = animatedText;
        charIndex++;
        typingTimeout = setTimeout(typeAnimation, 200);
      } else {
        erasingTimeout = setTimeout(eraseAnimation, 2000);
      }
    };

    const eraseAnimation = () => {
      if (charIndex > 0) {
        animatedText = animatedText.slice(0, -1);
        textElement.textContent = animatedText;
        charIndex--;
        erasingTimeout = setTimeout(eraseAnimation, 100);
      } else {
        textIndex = (textIndex + 1) % textArray.length;
        typingTimeout = setTimeout(typeAnimation, 500);
      }
    };

    typeAnimation();

    return () => {
      clearTimeout(typingTimeout);
      clearTimeout(erasingTimeout);
    };
  }, []);
  return (
    <div className="Home-img ">
      <div className="Home-text">
        <h1 className="typing-text">
          Consol <span>Express </span>
        </h1>
        <p>
          Our transportation division offers reliable and efficient solutions
          for
          <br />
          all your transportation needs.
        </p>
      </div>
    </div>
  );
}

export default Home;
