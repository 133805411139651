import React from "react";
import video from "../../assets/video.mp4";
import "./Video.css";

const Video = () => {
  return (
    <div className="video-container">
      <video
        className="video-background"
        src={video}
        autoPlay
        muted
        loop
      ></video>
      <div className="video-overlay"></div>
    </div>
  );
};

export default Video;
