import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../../assets/Logo.png";
import { Link } from "react-scroll";
import Menu from "../../assets/Menu.png";

function NavBar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const toggleMenu = () => {
    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
  };
  return (
    <nav className="container dark-nav">
      <img src={Logo} className="logo" alt="" />
      <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
        <li>
          <Link to="Home-img" smooth={true} offset={0} duration={1000}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="about-us-container"
            smooth={true}
            offset={-250}
            duration={1000}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="products-container"
            smooth={true}
            offset={-200}
            duration={1000}
          >
            Services
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} offset={-240} duration={1000}>
            Contact Us
          </Link>
        </li>
      </ul>
      <img src={Menu} alt="" onClick={toggleMenu} className="menu-icon" />
    </nav>
  );
}

export default NavBar;
