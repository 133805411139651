import React from "react";
import ContactCard from "./Contact-Card";
import "./Contact-Card.css";
import { data, data2 } from "./data";
const MainCard = () => {
  return (
    <>
      <div className="top-content">
        <h5 className="OurService">Chennai Branch</h5>
      </div>
      <div data-aos="fade-up" className="cardcontainer">
        {data.map((item, index) => (
          <ContactCard
            key={index}
            position={item.position}
            name={item.Name}
            mail={item.MailID}
            contact={item.contact}
          />
        ))}
      </div>
      <div className="top-content">
        <h5 className="OurService">Coimbatore Branch</h5>
      </div>
      <div data-aos="fade-up" className="cardcontainer">
        {data2.map((item, index) => (
          <ContactCard
            key={index}
            position={item.position}
            name={item.Name}
            mail={item.MailID}
            contact={item.contact}
          />
        ))}
      </div>
    </>
  );
};

export default MainCard;
