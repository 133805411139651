import React from "react";
import "./Aboutus.css";
import Img1 from "../../assets/vector-1.png";
import Img2 from "../../assets/ab4.jpg";
import Img3 from "../../assets/ab5.png";
import Img4 from "../../assets/ab6.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTruckFast } from "@fortawesome/free-solid-svg-icons";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="image-container position-relative">
        <div className="image-1 position-absolute">
          <img src={Img1} alt="" />
        </div>
        <div className="image-2">
          <img src={Img2} alt="" />
        </div>
        <div data-aos="slide-right" className="image-3">
          <img src={Img3} alt="" />
        </div>

        <div data-aos="zoom-in-up" className="image-4">
          <img src={Img4} alt="" />
        </div>
      </div>
      <div data-aos="fade-up" className="text-content">
        <h6>
          <FontAwesomeIcon
            style={{ color: "orange", fontSize: "18px" }}
            icon={faTruckFast}
          />
          <span>Who We are</span>
        </h6>
        <h1>Delivering world class service</h1>
        <ul>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Sea Freight</span>
          </li>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Air Freight</span>
          </li>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Road Freight</span>
          </li>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Customs Clearance</span>
          </li>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Project Cargo</span>
          </li>
          <li>
            <FontAwesomeIcon className="about-arrow-icon" icon={faArrowRight} />
            <span>Warehousing</span>
          </li>
        </ul>
        <p>
          Consol Express was established in the year 2017, we serve to be one of
          the fastest growing freight forwarder in the region supported by a
          team of young and strong people. The agency serves as a network to
          reckon for all your freight forwarding and supply chain requirements.
          We offer customized transport solutions at the highest level of
          reliability and quality. As a modern company with a long standing
          tradition, Consol Express serves as a best logistic partner.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
