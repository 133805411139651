import React from "react";
import "./Contact-Card.css";

const ContactCard = ({ name, position, mail, contact }) => {
  return (
    <div className="card">
      <div className="hover-layer"></div>
      <h3>{position}</h3>
      <h5>{name}</h5>
      <p>Email: {mail}</p>
      <p>Contact: {contact}</p>
    </div>
  );
};

export default ContactCard;
