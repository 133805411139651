import React, { useState } from "react";
import "./Contactus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { faLocationArrow, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contactus = () => {
  const [result, setResult] = useState("");

  const notifySuccess = () => toast.success("File Submitted Successfully!");
  const notifySending = () => toast.warn("Submitting...");

  const handleSubmit = async (event) => {
    event.preventDefault();
    notifySending();

    const formData = new FormData(event.target);
    formData.append("access_key", "5fe4f1ef-11ab-45f9-8b84-ccb636bdd0c3");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        event.target.reset();
        setResult("");
        notifySuccess();
      } else {
        setResult(data.message);
      }
    } catch (error) {
      setResult("An error occurred. Please try again later.");
    }
  };

  return (
    <div data-aos="fade-up" className="contact">
      <div className="contact-col">
        <h1>Get in touch</h1>
        <p>
          Feel free to reach out through the contact form or find our contact
          information below. Your feedback, questions, and suggestions are
          important to us as we strive to provide exceptional service.
        </p>
        <ul>
          <li>
            <h3>
              <FontAwesomeIcon className="icons" icon={faEnvelopeOpen} /> Mail:
            </h3>{" "}
            info@consol.express
          </li>
          <li>
            <h3>
              <FontAwesomeIcon className="icons" icon={faPhone} /> Call:
            </h3>{" "}
            +91-7550014100
          </li>
          <li>
            <h3>
              <FontAwesomeIcon className="icons" icon={faLocationArrow} />{" "}
              Visit:
            </h3>{" "}
            Chennai :Old # 125, New # G - 67, 1st Avenue, Anna Nagar East,2nd
            Floor Chennai 600 102-India.
            <br />
          </li>
        </ul>
      </div>
      <div className="contact-col">
        <form onSubmit={handleSubmit}>
          <h2>Send a message</h2>
          <div className="input-group">
            <div className="input-row">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                required
                autoComplete="name"
              />
            </div>
            <div className="input-row">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
                autoComplete="email"
              />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="tel">Phone Number</label>
            <input
              type="tel"
              id="tel"
              name="phone"
              placeholder="Enter your mobile number"
              required
              autoComplete="tel"
            />
          </div>
          <div className="input-group">
            <label htmlFor="message">Write Your message here</label>
            <textarea
              name="message"
              id="message"
              rows="6"
              placeholder="Enter your message"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn dark-btn">
            Submit
          </button>
          {result && <span className="error">{result}</span>}
          <ToastContainer />
        </form>
      </div>
    </div>
  );
};

export default Contactus;
