import React from "react";
import "./GroupageSerivce.css";
import AutoplaySlider from "./AutoScroll/AutoScroll";

const GroupageSerivce = () => {
  return (
    <div data-aos="fade-up" className="GroupageSerivce">
      <p>
        Consol Express is specialized in LCL consolidation, with great expertise
        and knowledge in handling LCL shipments; we have become one of the
        leading consolidator. We have dedicated personnel in all departments to
        offer the best service in the industry. We work with more than 150
        agents in various countries and presently offer service to more than 70
        base ports world wide.
      </p>
      <p>
        All our shipments go through a strict process in-order to meet the
        International Standards of Safety, to ensure that our customer's
        valuable shipments are made secure and arrives on time at destinations
        Wherever you need your shipments to be moved, our Consol network will
        always be ready to serve you; regardless of your shipment quantity, we
        would like to grow our business together with you. We always promise to
        give all services at "ONE POINT" i.e CONSOL EXPRESS
      </p>
      <div className="image-div">
        <AutoplaySlider />
      </div>
    </div>
  );
};

export default GroupageSerivce;
