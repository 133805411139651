import React from "react";
import "./AutoScroll.css";
import img9 from "../../../assets/9.png";
import corrosive from "../../../assets/corrosive.png";
import dangerous from "../../../assets/dangerous.png";
import explosive from "../../../assets/explosive.png";
import flammablegas2 from "../../../assets/flammable gas 2.png";
import flammable from "../../../assets/flammable.png";
import flammanle3 from "../../../assets/flammanle 3.png";
import infectious from "../../../assets/infectious.png";
import nonflammablegas from "../../../assets/non flammable gas.png";
import organicperoxide from "../../../assets/organic peroxide.png";
import oxidizer from "../../../assets/oxidizer.png";
import poison from "../../../assets/poison.png";
import spontaneously from "../../../assets/spontaneously.png";
import radioactive from "../../../assets/radioactive.png";

function AutoplaySlider() {
  return (
    <div className="body">
      <div className="slider">
        <div className="slide-track">
          <div className="slide">
            <img src={img9} alt="slide1" />
          </div>
          <div className="slide">
            <img src={corrosive} alt="slide2" />
          </div>
          <div className="slide">
            <img src={dangerous} alt="slide3" />
          </div>
          <div className="slide">
            <img src={explosive} alt="slide4" />
          </div>
          <div className="slide">
            <img src={flammablegas2} alt="slide5" />
          </div>
          <div className="slide">
            <img src={flammable} alt="slide6" />
          </div>
          <div className="slide">
            <img src={flammanle3} alt="slide7" />
          </div>
          <div className="slide">
            <img src={infectious} alt="slide8" />
          </div>
          <div className="slide">
            <img src={nonflammablegas} alt="slide9" />
          </div>
          <div className="slide">
            <img src={organicperoxide} alt="slide10" />
          </div>
          <div className="slide">
            <img src={oxidizer} alt="slide11" />
          </div>
          <div className="slide">
            <img src={poison} alt="slide12" />
          </div>
          <div className="slide">
            <img src={radioactive} alt="slide13" />
          </div>
          <div className="slide">
            <img src={spontaneously} alt="slide14" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AutoplaySlider;
