import React from "react";
import "./Tittle.css";
const Tittle = ({ subtitle, content }) => {
  return (
    <div className="tittle ">
      <div className="lines "></div>
      <p>{subtitle}</p>
      <h2>{content}</h2>
    </div>
  );
};

export default Tittle;
