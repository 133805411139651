import React from "react";
import "./Card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faPlane,
  faShip,
  faWarehouse,
  faPeopleGroup,
  faBox,
} from "@fortawesome/free-solid-svg-icons";
const Products = () => {
  return (
    <div className="products-container">
      <div className="card-container">
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faShip} />

            <h3>Sea Freight</h3>
            <p>
              We offer a personalized FCL and LCL shipping service and with a
              strategically placed network of partners globally...
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faPlane} />
            <h3>Air Freight</h3>
            <p>
              We are determined to deliver the most efficient and effective
              airfreight routing of your shipment through...
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faTruck} />

            <h3>Road Freight</h3>
            <p>
              Road transport is the most convenient method of delivery as goods
              can be collected from the point of supply..
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faPeopleGroup} />
            <h3>Customs Clearance</h3>
            <p>
              We provide a comprehensive customs clearance service for shipments
              arriving in United Arab of Emirates port as well as
              facilitating...
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faBox} />
            <h3>Project Cargo</h3>
            <p>
              We manage your need to procure and deliver global cargoes for a
              major project, transport oversized equipment to an overseas...
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="card-product">
          <div className="card-content-product">
            <FontAwesomeIcon className="product-icon" icon={faWarehouse} />
            <h3>Ware Housing</h3>
            <p>
              We will develop a warehouse solution that fits your warehouse
              storage and distribution requirements, that includes vendor and...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
